import { fromJS, List } from "immutable";
import { constructCourseWiseList } from "@/Utils/jsUtils";

const createGradeBookFullFiled = (state) => {
  state = state.set("isLoading", false);
  return state;
};
const updateGradeBookFullFiled = (state) => {
  state = state.set("isLoading", false);
  return state;
};
const deleteGradeBookFullFiled = (state) => {
  state = state.set("isLoading", false);
  return state;
};
const fetchAcademicYearFullFiled = (state, action) => {
  state = state
    .set("isLoading", false)
    .set("academicYear", fromJS(action.payload));
  return state;
};
const fetchUserProgramDetailsFullFiled = (state, action) => {
  state = state
    .set("isLoading", false)
    .set("userProgramDetails", fromJS(action.payload));
  return state;
};
const fetchGradeBookListPending = (state) => {
  state = state.set("isLoading", true);
  // .set("gradeBookList", List());
  return state;
};
const fetchGradeBookListFullFiled = (state, action) => {
  const { params, data } = action.payload;
  const { academicYearId, programId, courseId } = params;
  const cacheKey = `${academicYearId}+${programId}+${courseId}`;
  state = state
    .set("isLoading", false)
    .setIn(["gradeBookList", cacheKey], fromJS(data));
  return state;
};
const fetchSingleProgramDetailsFullFiled = (state, action) => {
  const { params, data } = action.payload;
  const { _programId, _academicYear } = params;
  const cacheKey = `${_programId}+${_academicYear}`;
  state = state
    .set("isLoading", false)
    .setIn(["programData", cacheKey], fromJS(data));
  return state;
};
const fetchSingleFullFiled = (state, action) => {
  state = state
    .set("isLoading", false)
    .set("gradeBookData", fromJS(action.payload));
  return state;
};
const fetchGradesFulfilled = (state, action) => {
  state = state.set("isLoading", false).set("grades", fromJS(action.payload));
  return state;
};

const fetchCourseExamsFulfilled = (state, action) => {
  const { params, data } = action.payload;
  const { academicYear, programId, courseId } = params;
  const { start, end } = academicYear;
  const cacheKey = `${start}+${end}+${programId}+${courseId}`;
  state = state
    .set("isLoading", false)
    .setIn(["courseExams", cacheKey], fromJS(data));
  return state;
};

const fetchStudentProgramsFulfilled = (state, action) => {
  state = state
    .set("isLoading", false)
    .set("studentPrograms", fromJS(action.payload));
  return state;
};

const fetchStudentProgramDetailsFullFiled = (state, action) => {
  state = state
    .set("isLoading", false)
    .set("userProgramDetails", fromJS(action.payload));
  return state;
};

const fetchStudentGradeBooksFulfilled = (state, action) => {
  const { params, data } = action.payload;
  const { academicYearId, programId } = params;
  const cacheKey = `${academicYearId}+${programId}`;
  const constructedData = constructCourseWiseList(fromJS(data));
  state = state
    .set("isLoading", false)
    .setIn(["gradeBookList", cacheKey], constructedData);
  return state;
};

const fetchPreviousGbFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("studentPreviousGb", fromJS(action.payload));
};

const fetchImpactDataFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("impactTableData", fromJS(action.payload));
};

const fetchGradeCountFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("studentGradeCounts", fromJS(action.payload));
};

const fetchStudentsFulfilled = (state, action) => {
  const { data, isGradeFilter } = action.payload;
  const key = isGradeFilter ? "gradeWiseStudents" : "studentsList";
  const payload = fromJS(data);
  const isFirstPage = payload.get("pageNo", 1) === 1;
  if (isFirstPage) return state.set("isLoading", false).set(key, payload);

  const studentsFromRedux = state.getIn([key, "students"], List());
  const studentsFromResponse = payload.get("students", List());
  const combinedStudents = studentsFromRedux.merge(studentsFromResponse);

  return state
    .set("isLoading", false)
    .set(key, payload.set("students", combinedStudents));
};

const addGradeRevisionFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("gradeRevision", fromJS(action.payload));
};

const fetchFailedStudentsFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("failedStudents", fromJS(action.payload));
};

const fetchHighestMarkFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("highestMark", fromJS(action.payload));
};

const fetchGenderCountFulfilled = (state, action) => {
  return state
    .set("isLoading", false)
    .set("genderCount", fromJS(action.payload));
};

const fetchStatusCountFulfilled = (state, action) => {
  const { data, applyCondition } = action.payload;
  state = state.set("isLoading", false);
  if (!applyCondition) state = state.set("statusCount", fromJS(data));
  return state;
};

export {
  fetchAcademicYearFullFiled,
  fetchUserProgramDetailsFullFiled,
  fetchGradeBookListFullFiled,
  fetchSingleFullFiled,
  createGradeBookFullFiled,
  updateGradeBookFullFiled,
  deleteGradeBookFullFiled,
  fetchGradeBookListPending,
  fetchSingleProgramDetailsFullFiled,
  fetchGradesFulfilled,
  fetchCourseExamsFulfilled,
  fetchStudentProgramsFulfilled,
  fetchStudentProgramDetailsFullFiled,
  fetchStudentGradeBooksFulfilled,
  fetchPreviousGbFulfilled,
  fetchImpactDataFulfilled,
  fetchGradeCountFulfilled,
  fetchStudentsFulfilled,
  addGradeRevisionFulfilled,
  fetchFailedStudentsFulfilled,
  fetchHighestMarkFulfilled,
  fetchGenderCountFulfilled,
  fetchStatusCountFulfilled,
};
