import axios from "@/axios";
import { GB_TYPE_REGULAR } from "@/Utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchUserProgramDetails = createAsyncThunk(
  "dashboard/fetchUserProgramDetails",
  async (params) => {
    const response = await axios.get("grade-book/setting/children", { params });
    return response?.data?.data;
  }
);
const fetchSingleProgramDetails = createAsyncThunk(
  "dashboard/fetchSingleProgramDetails",
  async ({ params } = {}) => {
    const response = await axios.get(
      "grade-book/dashboard/course-student-count",
      { params }
    );
    return { params, data: response?.data?.data };
  }
);
const fetchGradeBookList = createAsyncThunk(
  "dashboard/fetchGradeBookList",
  async ({ params }) => {
    const response = await axios.get("grade-book/dashboard", { params });
    return { params, data: response?.data?.data };
  }
);
const createRegularGradeBook = createAsyncThunk(
  "dashboard/createRegularGradeBook",
  async ({ postData }) => {
    const response = await axios.post("grade-book/dashboard/regular", postData);
    return response?.data;
  }
);
const updateGradeBookData = createAsyncThunk(
  "dashboard/updateGradeBookData",
  async ({ data }) => {
    const response = await axios.put(`grade-book/dashboard`, data);
    return response?.data;
  }
);
const deleteGradeBook = createAsyncThunk(
  "dashboard/deleteGradeBook",
  async ({ params }) => {
    const response = await axios.delete("grade-book/dashboard", { params });
    return response?.data;
  }
);

const fetchSingleGradeBook = createAsyncThunk(
  "dashboard/fetchGradeBook",
  async ({ params, isStudent = false }) => {
    const response = await axios.get(
      `grade-book/${isStudent ? "student" : "dashboard"}`,
      { params }
    );
    return response?.data?.data;
  }
);
const fetchAcademicYear = createAsyncThunk(
  "dashboard/fetchAcademicYear",
  async () => {
    const response = await axios.get("academic-year");
    return response?.data?.data;
  }
);

const fetchGrades = createAsyncThunk(
  "dashboard/fetchGrades",
  async ({ isStudent = false }) => {
    const response = await axios.get(
      `grade-book/${isStudent ? "student" : "dashboard"}/grade`
    );
    return response?.data?.data;
  }
);

const fetchCourseExams = createAsyncThunk(
  "dashboard/fetchCourseExams",
  async (params) => {
    const response = await axios.get("grade-book/dashboard/exam", {
      params,
    });
    return { params, data: response?.data?.data };
  }
);

const fetchStudentPrograms = createAsyncThunk(
  "dashboard/fetchStudentPrograms",
  async (params) => {
    const response = await axios.get("grade-book/student/program", {
      params,
    });
    return response?.data?.data;
  }
);

const fetchStudentProgramDetails = createAsyncThunk(
  "dashboard/fetchStudentProgramDetails",
  async (params) => {
    const response = await axios.get("grade-book/student/children", { params });
    return response?.data?.data;
  }
);

const fetchStudentGradeBooks = createAsyncThunk(
  "dashboard/fetchStudentGradeBooks",
  async (params) => {
    const response = await axios.get("grade-book/student", { params });
    return { params, data: response?.data?.data };
  }
);

const addNewColumnHeader = createAsyncThunk(
  "newColumnHeader",
  async ({ payload }) => {
    const response = await axios.put(
      "grade-book/dashboard/column/regular/new",
      payload
    );
    return response?.data?.data;
  }
);

const addAverageMarksColumn = createAsyncThunk(
  "addAverageMarks",
  async ({ payload }) => {
    const response = await axios.put(
      "grade-book/dashboard/column/regular/average-mark",
      payload
    );
    return response?.data?.data;
  }
);

const addExtraOrReduceMarks = createAsyncThunk(
  "addExtraOrReduceMarks",
  async ({ payload, isExtraMarks }) => {
    const type = isExtraMarks ? "extra-mark" : "reduce-mark";
    const response = await axios.put(
      `grade-book/dashboard/column/regular/${type}`,
      payload
    );
    return response?.data?.data;
  }
);

const updatePassingCriteria = createAsyncThunk(
  "updatePassingCriteria",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/passing-criteria`,
      payload
    );
    return response?.data?.data;
  }
);

const updateGradeBookStatus = createAsyncThunk(
  "dashboard/updateGradeBookStatus",
  async ({ data }) => {
    const response = await axios.put(`grade-book/dashboard/status`, data);
    return response?.data?.data;
  }
);

const fetchGbStudents = createAsyncThunk(
  "dashboard/fetchGbStudents",
  async ({ params }) => {
    const response = await axios.get("grade-book/dashboard/student", {
      params,
    });
    return response?.data?.data;
  }
);

const addGradeRevision = createAsyncThunk(
  "dashboard/addGradeRevision",
  async ({ type, payload }) => {
    const url =
      type === "markAllocation"
        ? "mark-allocation"
        : type === "shiftWithinGrade"
        ? "shift-within-grade"
        : type === "shiftGrade"
        ? "shift-grade"
        : type === "passingPercentage"
        ? "passing-percentage"
        : "highest-mark";

    const response = await axios.put(
      `grade-book/dashboard/student/revision/${url}`,
      payload
    );
    return response?.data?.data;
  }
);

const updateGbStudents = createAsyncThunk(
  "dashboard/updateGbStudents",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/student/revision`,
      payload
    );
    return response?.data?.data;
  }
);

const addWeightAgeColumn = createAsyncThunk(
  "dashboard/addWeightAgeColumn",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/column/regular/weightage`,
      payload
    );
    return response?.data?.data;
  }
);

const importStudents = createAsyncThunk(
  "dashboard/importStudents",
  async ({ payload }) => {
    const response = await axios.post(
      `grade-book/dashboard/student/regular`,
      payload
    );
    return response?.data?.data;
  }
);

const removeColumn = createAsyncThunk(
  "dashboard/removeColumn",
  async ({ payload }) => {
    const response = await axios.delete(`grade-book/dashboard/column/regular`, {
      data: payload,
    });
    return response?.data?.data;
  }
);

const createResitGradeBook = createAsyncThunk(
  "dashboard/createResitGradeBook",
  async ({ postData }) => {
    const response = await axios.post("grade-book/dashboard/resit", postData);
    return response?.data;
  }
);

const fetchStudentPreviousGb = createAsyncThunk(
  "dashboard/fetchStudentPreviousGb",
  async ({ params, isStudent = false }) => {
    const url = isStudent
      ? "student/previous-table"
      : "dashboard/student/previous-table";
    const response = await axios.get(`grade-book/${url}`, { params });
    return response?.data?.data;
  }
);

const addResitExtraMark = createAsyncThunk(
  "dashboard/addResitExtraMark",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/column/resit/extra-mark`,
      payload
    );
    return response?.data?.data;
  }
);

const deleteExtraMark = createAsyncThunk(
  "dashboard/deleteExtraMark",
  async ({ payload }) => {
    const response = await axios.delete(
      `grade-book/dashboard/column/resit/extra-mark`,
      { data: payload }
    );
    return response?.data?.data;
  }
);

const deleteResitCondition = createAsyncThunk(
  "dashboard/deleteResitCondition",
  async ({ payload }) => {
    const response = await axios.delete(
      `grade-book/dashboard/column/resit/condition`,
      { data: payload }
    );
    return response?.data?.data;
  }
);

const addResitCondition = createAsyncThunk(
  "dashboard/addResitCondition",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/column/resit/condition`,
      payload
    );
    return response?.data?.data;
  }
);

const fetchImpactData = createAsyncThunk(
  "dashboard/fetchImpactData",
  async ({ postData }) => {
    const response = await axios.post(
      "grade-book/dashboard/column/resit/impact-table",
      postData
    );
    return response?.data?.data;
  }
);

const applyResitCondition = createAsyncThunk(
  "dashboard/applyResitCondition",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/column/resit/equivalence-apply`,
      payload
    );
    return response?.data?.data;
  }
);

const importResitStudents = createAsyncThunk(
  "dashboard/importResitStudents",
  async ({ payload }) => {
    const response = await axios.post(
      `grade-book/dashboard/student/resit`,
      payload
    );
    return response?.data?.data;
  }
);

const fetchGradeCount = createAsyncThunk(
  "dashboard/fetchGradeCount",
  async ({ id, type }) => {
    const url =
      type === GB_TYPE_REGULAR ? "regular-grade-count" : "resit-grade-count";
    const response = await axios.get(`grade-book/dashboard/student/${url}`, {
      params: { id },
    });
    return response?.data?.data;
  }
);

const fetchStudents = createAsyncThunk(
  "dashboard/fetchStudents",
  async ({ params, isGradeFilter }) => {
    const response = await axios.get("grade-book/dashboard/student/name", {
      params,
    });
    return { data: response?.data?.data, isGradeFilter };
  }
);

const fetchFailedStudents = createAsyncThunk(
  "dashboard/fetchFailedStudents",
  async (params) => {
    const response = await axios.get(
      "grade-book/dashboard/student/revision/weightage-grouping",
      { params }
    );
    return response?.data?.data;
  }
);

const fetchHighestMark = createAsyncThunk(
  "dashboard/fetchHighestMark",
  async (params) => {
    const response = await axios.get(
      "grade-book/dashboard/student/revision/highest-mark",
      { params }
    );
    return response?.data?.data;
  }
);

const fetchGenderCount = createAsyncThunk(
  "dashboard/fetchGenderCount",
  async (params) => {
    const response = await axios.get(
      "grade-book/dashboard/student/gender-count",
      { params }
    );
    return response?.data?.data;
  }
);

const fetchStatusCount = createAsyncThunk(
  "dashboard/fetchStatusCount",
  async ({ params, applyCondition = false }) => {
    const response = await axios.get(
      "grade-book/dashboard/student/status-count",
      { params }
    );
    return { data: response?.data?.data, applyCondition };
  }
);

const fetchConditionAppliedCount = createAsyncThunk(
  "dashboard/fetchConditionAppliedCount",
  async (params) => {
    const response = await axios.get(
      "grade-book/dashboard/student/condition-applied-count",
      { params }
    );
    return response?.data?.data;
  }
);

const checkConditionApplied = createAsyncThunk(
  "dashboard/checkConditionApplied",
  async (params) => {
    const response = await axios.get(
      "grade-book/dashboard/student/condition-not-applied",
      { params }
    );
    return response?.data?.data;
  }
);

const fetchStatusBasedStudents = createAsyncThunk(
  "dashboard/fetchStatusBasedStudents",
  async (params) => {
    const response = await axios.get("grade-book/dashboard/student/status", {
      params,
    });
    return response?.data?.data;
  }
);

export {
  fetchUserProgramDetails,
  fetchGradeBookList,
  createRegularGradeBook,
  updateGradeBookData,
  deleteGradeBook,
  fetchSingleGradeBook,
  fetchAcademicYear,
  fetchSingleProgramDetails,
  fetchGrades,
  fetchCourseExams,
  fetchStudentPrograms,
  fetchStudentProgramDetails,
  fetchStudentGradeBooks,
  addNewColumnHeader,
  addAverageMarksColumn,
  addExtraOrReduceMarks,
  updatePassingCriteria,
  updateGradeBookStatus,
  fetchGbStudents,
  addGradeRevision,
  updateGbStudents,
  addWeightAgeColumn,
  importStudents,
  removeColumn,
  createResitGradeBook,
  fetchStudentPreviousGb,
  addResitExtraMark,
  deleteExtraMark,
  deleteResitCondition,
  addResitCondition,
  fetchImpactData,
  applyResitCondition,
  importResitStudents,
  fetchGradeCount,
  fetchStudents,
  fetchFailedStudents,
  fetchHighestMark,
  fetchGenderCount,
  fetchStatusCount,
  fetchConditionAppliedCount,
  checkConditionApplied,
  fetchStatusBasedStudents,
};
