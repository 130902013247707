import axios from "@/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchSettings = createAsyncThunk("settings/fetch", async ({ params }) => {
  const response = await axios.get("grade-book/setting", { params });
  return { programId: params.programId, data: response?.data?.data };
});

const fetchExamsData = createAsyncThunk("exams/fetch", async ({ params }) => {
  const response = await axios.get(
    "grade-book/setting/exam-type/exam-type-and-category",
    { params }
  );
  return { data: response?.data?.data, programId: params.programId };
});

const postSettings = createAsyncThunk("settings/post", async ({ postData }) => {
  const response = await axios.post("grade-book/setting", postData);
  return { programId: postData.programId, data: response?.data?.data };
});

const putSettings = createAsyncThunk("settings/put", async ({ postData }) => {
  const response = await axios.put("grade-book/setting", postData);
  return response?.data?.data;
});

const postExamTypes = createAsyncThunk(
  "examtypes/post",
  async ({ postData }) => {
    const response = await axios.post("grade-book/setting/exam-type", postData);
    return response?.data?.data;
  }
);

const postExamCategories = createAsyncThunk(
  "examCategories/post",
  async ({ postData }) => {
    const response = await axios.post("grade-book/setting/category", postData);
    return response?.data?.data;
  }
);

const fetchAttemptType = createAsyncThunk("attempType/post", async () => {
  const response = await axios.get("attempt-type");
  return response?.data?.data;
});

const deleteExamType = createAsyncThunk(
  "examType/delete",
  async ({ params }) => {
    const response = await axios.delete("grade-book/setting/exam-type", {
      params,
    });
    return response?.data?.data;
  }
);

const deleteCategories = createAsyncThunk(
  "categories/post",
  async ({ params }) => {
    const response = await axios.delete("grade-book/setting/category", {
      params,
    });
    return response?.data?.data;
  }
);

const fetchPrograms = createAsyncThunk("dashboard/fetchPrograms", async () => {
  const response = await axios.get("program/name");
  return response?.data?.data;
});

export {
  fetchSettings,
  postSettings,
  fetchExamsData,
  putSettings,
  postExamTypes,
  postExamCategories,
  fetchAttemptType,
  deleteExamType,
  deleteCategories,
  fetchPrograms,
};
